<template>
    <CatTabs @tabClick='handleClick' :tabsName='tabsName' :tabname='lookup.tabname'>
        <template #search-r>
            <CatSearch :searchHead="tableHead" @search="getdatas" :searchForm='lookup.search'></CatSearch>
            <el-checkbox-group v-model="lookup.dataDimension" size="small" @change="dataDimensionC"
                v-if="dimension.length > 0">
                <el-tooltip v-for="(item, index) in dimension" :key="index" :content="item+'维度统计'" placement="bottom"
                    effect="light">
                    <el-checkbox-button :key="index" :value="item">
                        {{item}}
                    </el-checkbox-button>
                </el-tooltip>
            </el-checkbox-group>
        </template>
        <template #search>
            <div v-for="(item, index) in tableHead" :key="index">
                <el-date-picker v-model="lookup.search[item.property]" type="daterange" range-separator="-"
                    start-placeholder="开始时间" format="YYYY-MM-DD" value-format="YYYY-MM-DD" end-placeholder="结束时间"
                    :disabled-date="disabledDate" @calendar-change='pickerOptions' :visible-change="timeOptionRange=''"
                    v-if="index==0&&item.type === 'date-picker'" @change="getdatas()" size="small" />
                <el-date-picker v-model="lookup.search[item.property][1]" type="date" placeholder="选择时间"
                    format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="-"
                    v-if="index==0&&item.type === 'one-date-picker'" @change="getdatas" size="small" />
            </div>
        </template>
        <template #search-l>
            <el-button size="small" type="primary" @click="ExporttAPI('#table-data')">导出表
            </el-button>
        </template>
        <template #content>
            <CatTable :fileList='fileList' :tableHead='tableHead' :lookup='lookup' @currentPage='' @rowvl=''
                :loading='loading' />
        </template>
    </CatTabs>
</template>

<script>
    // @ is an alias to /src
    import CatTabs from '@/components/CatTabs.vue'
    import CatSearch from '@/components/CatSearch.vue'
    import CatTable from '@/components/CatTable.vue'
    //导出文件
    import jsonToExcel from '@/utils/jsonToExcel'
    import axios from "@/utils/request";
    import {
        mapState
    } from 'vuex'
    import {
        getabs
    } from '@/utils/tools'
    import {
        ElMessage,
        ElLoading,
    } from 'element-plus'
    export default {
        name: 'dataScreen',
        components: {
            CatTabs,
            CatSearch,
            CatTable
        },
        created() {
            //获取侧边列表
            getabs().then(v => {
                this.tabsName = v
                this.lookup.tabname = v[0]
                this.lookup.search['trade_date'] = [this.today, this.today]
                this.getdatas()
            })
        },
        computed: {
            ...mapState(['today', 'month']),
        },
        data() {
            return {
                dimension: ["销售", "小组", "品牌", "类目"],
                tableHeight: 400,
                innerHeight: 0,
                activeNames: 0,
                tabsName: [],
                tableHead: [],
                searchHead: [],
                lookup: {
                    tabname: '个人目标完成',
                    dataDimension: ["销售"],
                    search: {},
                    export: false,
                    currentPage: 1, //当前所在分页页数
                    pageSize: 20, //一页显示多少数据
                    total: 20, //总共有多少数据
                },
                timeOptionRange: '',
                loading: false,
                fileList: [],
            }
        },
        methods: {
            pickerOptions(time) {
                //保存用户在设置日期选择器选择的第一个日期
                this.timeOptionRange = time[0];
            },
            disabledDate(time) {
                let dataOptionRange = this.timeOptionRange;
                let secondNum = 60 * 60 * 24 * 30 * 1000; // 时间跨度为30天【一年以365天的时间计算】的限制
                if (dataOptionRange) {
                    return time.getTime() > dataOptionRange.getTime() + secondNum || time.getTime() < dataOptionRange
                        .getTime();
                }
            },
            handleClick(v) { //切换菜单
                this.fileList.length = 0
                this.lookup.tabname = v
                if (!this.lookup.tabname.includes('月')) {
                    // 如果切换到不是月数据的表就限制日期为1天
                    this.lookup.search.trade_date[0] = this.lookup.search.trade_date[1]
                }
                this.dimension = []
                this.lookup.dataDimension = []
                const datadimension = JSON.parse(window.localStorage.getItem('datadimension'))
                if (datadimension) {
                    this.lookup.dataDimension = datadimension
                }
                this.getdatas()
            },
            getdatas() {
                this.loading = true
                // 判断是否为月数据
                if (this.lookup.tabname.includes('月')) {
                    // 若统计月的数据限制开始日期为月的第一天
                    this.lookup.search.trade_date[0] = this.lookup.search.trade_date[1].slice(0, -2) + '01'
                }
                axios({
                    method: "post",
                    url: 'DataAnalyse',
                    data: this.lookup,
                }).then(res => {
                    if ('dataDimension' in res) {
                        this.dimension = res.dataDimension
                        this.lookup.dataDimension = res.useDimension
                    }
                    this.fileList = res.data
                    this.tableHead = res.tableHead
                    this.loading = false
                })
            },
            ExporttAPI(e) { //导出表
                this.loading = true
                this.lookup.export = true
                jsonToExcel(this.fileList, this.tableHead, this.lookup.tabname)
                this.loading = false
                this.lookup.export = false
            },
            dataDimensionC() {
                if (this.lookup.dataDimension.length == 0) {
                    this.lookup.dataDimension = [this.dimension[0]]
                }
                window.localStorage.setItem('datadimension', JSON.stringify(this.lookup.dataDimension))
                this.getdatas()
            }
        }
    }
</script>

<style scoped>
    .el-form-item {
        width: 45%;
    }

    .el-menu-vertical-demo {
        height: 100%;
    }

    .el-menu {
        min-height: 415px;
    }

    .card-header {
        padding-left: 16px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .taheader {
        /* height: 700px; */
        padding-left: 16px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>